// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/index.tsx");
  import.meta.hot.lastModified = "1730113364434.6223";
}
// REMIX HMR END

import { useLoaderData } from '@remix-run/react';
import { getCollections } from '~/providers/collections/collections';
import { CollectionCard } from '~/components/collections/CollectionCard';
import { BookOpenIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
export async function loader({
  request
}) {
  const collections = await getCollections(request, {
    take: 20
  });
  return {
    collections
  };
}
export default function Index() {
  _s();
  const {
    collections
  } = useLoaderData();
  const {
    t
  } = useTranslation();
  const headerImage = collections[0]?.featuredAsset?.preview;
  return <>
      <div className="relative">
        {/* Decorative image and overlay */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          {headerImage && <img className="absolute inset-0 w-full" src={headerImage + '?w=800'} alt="header" />}
          <div className="absolute inset-0 bg-gradient-to-br from-zinc-400 to-black mix-blend-darken" />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50" />
        <div className="relative max-w-3xl mx-auto py-32 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0">
          <div className="relative bg-zinc-800 bg-opacity-0 rounded-lg p-0">
            <h1 className="text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-yellow-600 via-red-500 to-blue-600">
              {t('vendure.title')}
            </h1>
          </div>

          <p className="mt-4 text-2xl text-white">
            {t('vendure.intro')}{' '}
            <a href="https://www.vendure.io" className="text-blue-300 hover:text-blue-500">
              Vendure
            </a>{' '}
            &{' '}
            <a href="~/routes/__cart/index" className="text-red-300 hover:text-red-500">
              Remix
            </a>
          </p>
          <p className="mt-4 text-gray-300 space-x-1">
            <BookOpenIcon className="w-5 h-5 inline" />
            <span>{t('common.readMore')}</span>
            <a className="text-primary-200 hover:text-primary-400" href="https://www.vendure.io/blog/2022/05/lightning-fast-headless-commerce-with-vendure-and-remix">
              {t('vendure.link')}
            </a>
          </p>
        </div>
      </div>

      <section aria-labelledby="category-heading" className="pt-24 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8">
        <div className="px-4 sm:px-6 lg:px-8 xl:px-0">
          <h2 id="category-heading" className="text-2xl font-light tracking-tight text-gray-900">
            {t('common.shopByCategory')}
          </h2>
        </div>

        <div className="mt-4 flow-root">
          <div className="-my-2">
            <div className="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
              <div className="grid justify-items-center grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8">
                {collections.map(collection => <CollectionCard key={collection.id} collection={collection} />)}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 px-4 sm:hidden">
          <a href="~/routes/__cart/index#" className="block text-sm font-semibold text-primary-600 hover:text-primary-500">
            {t('common.browseCategories')}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </section>
    </>;
}
_s(Index, "1Vy81FqO1Twq8CvvfUoCngZf6R8=", false, function () {
  return [useLoaderData, useTranslation];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;